var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "maxW": ['300px', '450px']
    }
  }, 'ul', _vm.$attrs, false), _vm._l(_vm.props.notifications, function (notification) {
    return _c('li', {
      key: notification.id,
      staticClass: "cursor-pointer flex gap-2 p-3 not-last:border-b not-last:border-black not-last:border-opacity-10 hover:bg-gray-100 active:bg-gray-200",
      class: {
        'bg-dgreen2 bg-opacity-50	hover:bg-opacity-80 active:bg-opacity-100': notification.isRead === 0
      },
      on: {
        "click": function click($event) {
          return _vm.emit('onClick', notification);
        }
      }
    }, [_c('div', [_c('BadgeUnread', {
      attrs: {
        "show": notification.isRead === 0
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": _vm.iconNotification,
        "height": "15",
        "width": "15",
        "fill": "black"
      }
    })], 1)], 1), _c('div', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      staticClass: "flex flex-col gap-1",
      attrs: {
        "fontSize": ['16px', '18px']
      }
    }, [_c('p', {
      staticClass: "font-medium",
      class: {
        'line-clamp-2': _vm.props.ellipsis !== false
      }
    }, [_vm._v(" " + _vm._s(notification.title) + " ")]), _c('p', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !!notification.content,
        expression: "!!notification.content"
      }],
      class: {
        'text-dgray2': true,
        'line-clamp-2': _vm.props.ellipsis !== false
      }
    }, [_vm._v(" " + _vm._s(notification.content) + " ")]), _c('p', {
      staticClass: "text-dgray3"
    }, [_vm._v(" " + _vm._s(_vm.formatTimeSince(notification.createdAt)) + " ")])])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }